<template>
  <v-dialog
    v-model="show"
    max-width="800"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>Set To another Order Item Id</v-card-title>
      <v-card-text>
        <v-form>
          <h3>Step 1: Filter slots by dates</h3>
          <p>Time slot options are auto-refreshed.</p>
          <v-date-picker
            v-model="dates"
            color="primary"
            range
            :events="functionEvents"
          ></v-date-picker>

          <h3>Step 2: Choose a new time slot</h3>
          <v-autocomplete
            v-model="form.inventory_id"
            placeholder="Time Slot"
            :items="slotOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
          ></v-autocomplete>

          <v-btn
            :loading="loading"
            color="primary"
            @click="handleSubmit"
          >
            Update
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateOrderItemInventory } from '@/api/order'
import { useNotifyErrors, useSlot, useTableOptions } from '@/composables'
import { dateSimple } from '@core/utils/filter'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import moment from 'moment'

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    orderItem: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false)

    const form = ref({
      inventory_id: props.orderItem.inventory_id,
    })

    const handleSubmit = () => {
      loading.value = true
      updateOrderItemInventory(props.orderItem.id, form.value)
        .then(() => {
          emit('close')
          emit('updated')
        })
        .catch(useNotifyErrors)
        .finally(() => {
          loading.value = false
        })
    }

    /**
     * ==== Slot Related ====
     */

    const {
      loadSkuIdSlots, matchSlots, options, functionEvents, dates, orderedDates,
    } = useSlot()

    const refresh = () => loadSkuIdSlots(
      props.orderItem.sku_id,
      useTableOptions(options.value, {
        started_at_from: moment(orderedDates.value[0]).startOf('month').format(),
        end_at_to: moment(orderedDates.value[1]).endOf('month').format(),
      }),
    )

    onMounted(() => refresh())

    watch([dates, options], refresh)

    const slotOptions = computed(() => matchSlots.value.map(s => ({
      title: `${dateSimple(s.started_at)} - ${dateSimple(s.ended_at)}`,
      value: s.id,
    })))

    return {
      slotOptions,
      form,
      loading,
      handleSubmit,

      dates,
      functionEvents,
    }
  },
}
</script>
